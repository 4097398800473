.priceContainer {
    max-width: 1536px;
    padding-left: 24px;
    padding-right: 24px;
    margin: auto;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.price_head {
    display: flex;
    align-items: center;
    flex-direction: column;
}



.head_bottom {
    margin-top: 60px;
    text-align: center;
    justify-content: center;
}

/* .rightImage {
    max-width: 100%;
    float: right;
    margin-right: 6%;
} */
.rightImage {
    max-width: 40%;
    margin-top: 10%;
    margin-left: 30%;
}

@media only screen and (max-width: 600px) {
    .rightImage {
        float: none;
        margin-right: 0;
        display: block;
        margin: 0 auto;
    }
}


.SaveUp {
    color: white;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-size: 17px;
    font-weight: 500;
}

.head_bottom h1 {
    color: white;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;

}

.FrequentlyAsked {
    margin: 0 auto;
    position: relative;
    border-radius: 20px;
    max-width: 700px;
}

@media only screen and (max-width: 600px) {
    .FrequentlyAsked {
        margin-left: auto;
        margin-right: auto;
    }
}

.Frequentlylist {
    border: 1px solid #0e1c20;
    background-color: #0c2436;

}

.HaveQues1 {
    display: flex;
    justify-content: center;
    align-items: center;

}

/* .HaveQues1Container {
    border: 1px solid #0c2436;
    border-radius: 17px;
    background-color: #0c2436;
    display: inline-block;
    color: white;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02em;
    padding: 0px; 
} */

.HaveQues1Container {
    background-color: #1A1D29;
    width: 110%;
    display: flex;
    justify-content: center;
    padding: 1%;
    border-radius: 15px;
}

.HaveQues {
    /* background-color: ; */
    color: white;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    /* identical to box height */
    letter-spacing: -0.02em;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContactSupport {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}


.head_top {
    text-align: right;
    margin-left: auto;
    gap: 30px;
    display: flex;
    margin-top: 20px;
}


.billingBtn {
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.price_table {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.columns {
    width: 100%;
    position: relative;
    flex: 1 1 20%;
}

.price {
    list-style-type: none;
    /* border: 1px solid #eee; */
    /* border: 2px solid #202634; */
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 22px;
}

.price:hover {
    border: #036ab7 solid 1px;
}

.tableHeader {
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
}

.price:hover .header {
    border-bottom: 1px solid #036ab7;
}

.price .header {
    background-color: #eaf3fa;
    color: #333;
    font-size: 25px;
    border-bottom: 1px solid #eee;
    border-radius: 5px 5px 0 0;
    background-image: none;
    padding-left: 30px;
}

.price li-1 {
    border-bottom: 1px solid #eee;
    padding: 15px 20px;
    text-align: left;
    list-style: none;
    background-image: url(../assets/tick.svg);
    background-repeat: no-repeat;
    background-position: 30px center;
    background-size: 20px;
    padding-left: 60px;
}

.tickli {
    /* border-bottom: 1px solid #eee; */
    padding: 15px 20px;
    text-align: left;
    list-style: none;
    background-image: url(../assets/tick.svg);
    background-repeat: no-repeat;
    background-position: 30px center;
    background-size: 20px;
    padding-left: 60px;
}

.notickli {
    border-bottom: 0px solid #eee;
    padding: 15px 20px;
    text-align: left;
    list-style: none;
    background-repeat: no-repeat;
    background-position: 30px center;
    background-size: 20px;
    padding-left: 60px;
}

.price .grey {
    background-color: #eee;
    font-size: 20px;
}

.price li.planPrice {
    background-image: none;
    /* padding-left: 30px; */
    text-align: center;
}

.price li.planPrice b {
    font-weight: 600;
    font-size: 20px;
}

.price li.sub_button {
    background-image: none;
    /*adding-left: 20px;*/
}


.sub_button .button {
    display: flex;

}

.button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-transform: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: linear-gradient(270deg, rgb(75, 165, 235) 0%, rgb(1, 104, 181) 100%);
    color: white;
    border-radius: 4px;
    padding: 12px 16px;
    height: 44px;
}

.whiteButton {
    background: none;
    color: #036ab7;
    border: #036ab7 solid 1px;
}

.pricing_botton {
    display: flex;
    margin-top: 50px;
}

.pricing_botton .pricing_botton_left {
    width: 40%;
}

.pricing_botton .pricing_botton_right {
    width: 60%;
}

.pricing_botton .pricing_botton_right .head p {
    width: 60%;
}

.addonCards {
    display: flex;
    gap: 20px;
}

.addoncard {
    border: #6c79a5 solid 1px;
    width: 50%;
    border-radius: 5px;
    padding: 20px;
    background: #f2f3fa;
    color: #000000;

}

.addoncard h2 {
    margin-top: 0;
}

.addon_count_input {
    border-radius: 3px;
    height: 30px;
    border: #333 solid 1px;
}

.addoncard .button {
    display: flex;
    background: #475469;
}

.yearlyBtn {
    border: none;
    color: #333;
}

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}


.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #234e6b;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}


/* top right*/
.ribbon-top-right {
    top: 0px;
    right: 0px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon-top-right::before {
    top: 0;
    left: 0;
}

.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}

.ribbon-top-right span {
    left: -15px;
    top: 30px;
    transform: rotate(45deg);
}



@media only screen and (max-width: 1200px) {

    .columns {
        flex: 1 1 40%;
    }

    .addonCards {
        flex-direction: column;
    }

    .addoncard {
        width: auto;
    }

    .pricing_botton .pricing_botton_right .head p {
        width: 100%;
    }

}

@media only screen and (max-width: 991px) {
    .priceContainer {
        width: 100%;
    }

    .columns {
        flex: 1 1 40%;
    }

    .pricing_botton {
        flex-direction: column;
    }

    .pricing_botton .pricing_botton_left,
    .pricing_botton .pricing_botton_right {
        width: 100%;
    }

    .addonCards {
        flex-direction: row;
    }

    .addoncard {
        width: 80%;
    }

    .head_top {
        text-align: center;
        margin-left: inherit;
    }

}


@media only screen and (max-width: 767px) {
    .columns {
        flex: 1 1 100%;
    }

    .addonCards {
        flex-direction: column;
    }
}